/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"

export const onClientEntry = () => {
  // Insert the Tawk.to script
  const script = document.createElement("script")
  script.src = "https://embed.tawk.to/67873b56825083258e052ddf/1ihk3vihm"
  script.async = true
  script.charset = "UTF-8"
  script.setAttribute("crossorigin", "*")
  document.head.appendChild(script)
}
